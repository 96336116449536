.custom-select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0;
  padding: 10px 12px;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
}

.custom-select:hover {
  border-color: black;
}

.custom-select:focus {
  border-color: #303f9f;
  outline: none;
}

.custom-label {
  transform: translateY(-6px) scale(0.75);
  transform-origin: top left;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  padding: 0;
  position: absolute;
  top: -2px;
  background-color: #fff;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
}

.custom-select:focus + .custom-label {
  color: #303f9f;
}

.custom-titulo {
  color: #757575;
}
