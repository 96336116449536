.custom-label-margin > label {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .autocomplete-class {
    width: 80%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .autocomplete-class {
    width: 50%;
  }
}
