.cardContent {
    max-width: 100%;
    overflow-x: auto; 
  }

  .tu-archivo-de-estilos {
    width: 400px; 
    align-items: center;
    height: 38px;
  }

  .boton1 {
    width: 100%; 
    align-items: stretch;
    height: 70%;

  }

  .boton2 {
    width: 100%; 
    align-items: stretch;
    height: 90%;

  }

  .tu-archivo-de-estilos1 {

    height: 19px;
  }
  .tu-archivo-de-estilos2 {

    height: 40px;

      font-size: 0.875rem;
  }

  .same-size-container {
    width: 100%;
    height: 100%;
  }

  .circular-progress-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    position: absolute;
    display: inline-flex;
    width: "100px";  
    height: "100px"; 
    background-color: rgba(227, 13, 13, 0.3);
  }

  
  .progress {
    height: 90px; 
  
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cardContentWithMargin {
    margin-top: 40px; 
    
  }

  .center-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }

  .centered-link {
    display: flex;
    justify-content: center;

  }